.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff; /* Cor de fundo semelhante à da barra de pesquisa */
    padding: 5px 20px; /* Padding similar à da search bar */
    width: 100%;
    max-width: 400px; /* Mesma largura máxima da search bar */
    margin: 0 auto; /* Centraliza o footer */
    position: fixed;
    bottom: 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .footer-icons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 400px; /* Alinha a largura com a da search bar */
  }
  
  .footer-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 14px;
  }
  
  .footer-icon span {
    margin-top: 5px;
    font-size: 12px;
  }
  
  .footer-icon:hover {
    color: #FFA500; /* Cor ao passar o mouse */
  }
  
  .footer-icon svg {
    font-size: 20px; /* Ajusta o tamanho dos ícones */
  }
  