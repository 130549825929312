.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f5; /* cinza claro */
  border-radius: 10px; /* bordas arredondadas */
  padding: 5px 15px;
  width: 100%;
  max-width: 400px; /* Máxima largura da search bar */
  margin: 20px auto; /* Centraliza a search bar, com espaçamento em cima e embaixo */
}

.search-icon, .microphone-icon {
  color: #a0a0a0; /* cor dos ícones */
  font-size: 20px;
}

.search-input {
  border: none;
  outline: none;
  background: transparent;
  flex: 1; /* Permite que o campo de input ocupe o espaço restante */
  font-size: 16px;
  padding: 5px 10px;
  color: #333;
}

.search-input::placeholder {
  color: #a0a0a0;
}

/* Responsividade */
@media (max-width: 600px) {
  .search-bar {
      width: 90%; /* Aumenta a largura para 90% em telas menores */
      margin: 10px auto; /* Centraliza com espaçamento menor */
      padding: 5px 10px; /* Ajusta o padding */
  }

  .search-input {
      font-size: 14px; /* Reduz o tamanho da fonte em telas pequenas */
  }

  .search-icon, .microphone-icon {
      font-size: 18px; /* Ajusta o tamanho dos ícones para telas menores */
  }
}
