body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #ffffff; /* fundo branco */
  }
  
  .home-container {
    display: flex;
    align-items: center; /* centraliza verticalmente */
    justify-content: center; /* centraliza horizontalmente */
    height: 100vh; /* altura total da tela */

  }
  
  .app-logo {
    width: 30vw; /* ajusta a logo para 30% da largura da tela */
    max-width: 150px; /* define um limite máximo para a largura */
    height: auto;
  }
  
  @media (max-width: 600px) {
    .app-logo {
      width: 80vw; /* logo maior em telas menores */
    }
  }
  