/* Container principal */
.main-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: white;
  padding: 0;
  margin: 0;
  overflow-x: hidden; /* Garante que a rolagem lateral seja evitada */
}

/* Caixa principal */
.main-screen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 400px; /* Largura máxima da caixa */
  text-align: center;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
}

.title {
  font-size: 4vh;
  font-weight: normal;
  color: #333;
  margin-bottom: 10px;
  margin-right: 310px;
  text-align: center;
}

/* Barra de pesquisa */
.search-bar-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  padding: 10px 0;
  margin: 0 auto 40px auto; /* Centraliza a barra de pesquisa */
  width: 80%;
  max-width: 450px; /* Largura máxima da barra de pesquisa */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsivo para telas menores */
@media (max-width: 768px) {
  .main-screen-content {
    max-width: 100%; /* Ajusta a largura da caixa para dispositivos móveis */
  }

  .title {
    font-size: 8vw; /* Ajusta o tamanho da fonte para telas pequenas */
    margin-bottom: 2vh;
    text-align: center;
    margin-right: 290px;
    margin-left: 10px;
  }

  .search-bar-container {
    width: 100%; /* Barra de pesquisa com 85% da largura */
    max-width: 100%; /* Limita a largura máxima da barra de pesquisa */
    margin-left: auto; /* Centraliza a barra de pesquisa */
    margin-right: auto;
  }
}
