.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    width: 100%;
    max-width: 400px; /* Tamanho fixo para o card, tanto em telas grandes quanto pequenas */
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: auto; /* Centraliza o card */
    margin-right: auto; /* Centraliza o card */
}

/* Estilo da imagem do card */
.card-image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-bottom: 50px;
    margin-right: 15px;
    object-fit: cover;
}

/* Conteúdo do card */
.card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Cabeçalho do card */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Título do card */
.card-title {
    font-size: 1em;
    font-weight: bold;
    margin-top: 0;
}

/* Ícone de favorito */
.favorite-icon {
    font-size: 1.5em;
    transition: color 0.3s ease;
    cursor: pointer;
}

/* Cores para ícones de favoritos */
.favorite {
    color: rgba(238, 121, 14, 1);
}

.not-favorite {
    color: #ccc;
}

/* Descrição do card */
.card-description {
    font-size: 0.9em;
    color: #666;
    margin-top: 10px;
}

/* Detalhes do card */
.card-details {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    flex-wrap: wrap; /* Permite que os itens se ajustem no espaço disponível */
}

/* Ajuste do conteúdo para alinhar local e duração à esquerda e nota e preço à direita */
.card-info-left,
.card-info-right {
    display: flex;
    flex-direction: column;
    font-size: 0.85em;
    color: #888;
    gap: 5px;
    width: 45%; /* Limita a largura para que caibam dois itens em cada lado */
}

.card-info-left {
    align-items: flex-start; /* Alinha à esquerda */
}

.card-info-right {
    align-items: flex-end; /* Alinha à direita */
}

/* Estilo de localização, duração, avaliação e preço */
.card-location,
.card-duration,
.card-rating,
.card-price {
    display: flex;
    align-items: center;
    white-space: nowrap; /* Impede a quebra de linha no texto */
    overflow: hidden; /* Oculta qualquer parte do texto que ultrapasse */
    text-overflow: ellipsis; /* Adiciona "..." se o texto for longo demais */
}

.icon-location,
.icon-duration,
.icon-rating {
    color: rgba(238, 121, 14, 1);
    margin-right: 5px;
}

.card-price {
    font-weight: bold;
    color: #000000;
}

/* Ajustes para telas menores */
@media (max-width: 600px) {
    .card {
        padding: 10px;
        width: 90%; /* Reduz a largura para 90% da tela */
        max-width: 400px; /* Mantém o mesmo tamanho do card para dispositivos móveis */
        margin-left: auto; /* Centraliza o card */
        margin-right: auto; /* Centraliza o card */
    }

    .card-image {
        width: 70px; /* Reduz a imagem */
        height: 70px; /* Reduz a imagem */
        margin-bottom: 50px; /* Ajuste no espaço abaixo da imagem */
    }

    .card-title {
        font-size: 0.9em; /* Ajuste do tamanho da fonte */
    }

    .favorite-icon {
        font-size: 1.2em; /* Ajuste no tamanho do ícone de favorito */
    }

    .card-details {
        flex-direction: row; /* Alinha os detalhes em linha em telas menores */
        justify-content: space-between;
    }

    .card-info-left,
    .card-info-right {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 45%; /* Limita a largura dos elementos */
    }

    .card-info-right {
        align-items: flex-end;
    }

    .card-location,
    .card-duration,
    .card-rating,
    .card-price {
    display: flex;
    align-items: center;
    word-break: break-word; /* Quebra a linha após o número de caracteres */
    max-width: 50ch; /* Define o limite de caracteres */
    }
}